import "./App.css";
import App from "./App.js";
import { useParams } from "react-router-dom";
import { ContentBone, ContentBone2, ContentBone3 } from "./contentBone.js";

function Content() {
  let { contentid } = useParams();
    return (
      <>
        <ContentBone />
        <ContentBone2 />
        <ContentBone3 />
      </>
    );
    }

export default Content;
