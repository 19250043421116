import "./App.css";
import App from "./App.js";
import Content from "./Content.js";
import { useParams } from "react-router-dom";
import ContentBone from "./contentBone.js";

function LinkContent() {
  let { contentid } = useParams();

  //

  if (contentid == "e1") return "https://represocial.com/p/d3431/yeezy-700-v2-static-giveaway-by-at-heatsneak";

  // classic giveaways

  if (contentid == "e1g2") return "https://represocial.com/p/d3431/yeezy-700-v2-static-giveaway-by-at-heatsneak";

  if (contentid == "s1g2") return "https://repre.social/p/4b708/yeezy-700-v2-static-sorteo-de-at-heatsneak";

  if (contentid == "r1g2") return "https://repre.social/p/72f44/yeezy-700-v2-static-konkurs-ot-at-heatsneak";

  if (contentid == "f1g2") return "https://repre.social/p/f7577/yeezy-700-v2-static-concours-de-at-heatsneak";

  if (contentid == "g1g2") return "https://repre.social/p/d7bf0/yeezy-700-v2-static-gewinnspiel-von-at-heatsneak";

  // collab giveaways


  if (contentid == "e1cg2") return "https://represocial.com/p/d3431/yeezy-700-v2-static-giveaway-by-at-heatsneak";

  if (contentid == "s1cg2") return "https://repre.social/p/4b708/yeezy-700-v2-static-sorteo-de-at-heatsneak";

  if (contentid == "r1cg2") return "https://repre.social/p/72f44/yeezy-700-v2-static-konkurs-ot-at-heatsneak";

  if (contentid == "f1cg2") return "https://repre.social/p/f7577/yeezy-700-v2-static-concours-de-at-heatsneak";

  if (contentid == "g1g2") return "https://repre.social/p/d7bf0/yeezy-700-v2-static-gewinnspiel-von-at-heatsneak";

  // raffles

  if (contentid == "e1r2") return "https://represocial.com/p/6a684/nike-sb-dunks-raffle";

  if (contentid == "g1r2") return "https://represocial.com/p/6a684/nike-sb-dunks-raffle";

}

function YtLinkContent() {
  let { contentid } = useParams();

  //

  if (contentid.includes("g1")) return "https://www.youtube.com/embed/dUW39V9nASo";

  if (contentid.includes("e1")) return "https://www.youtube.com/embed/dUW39V9nASo";

  if (contentid.includes("s1")) return "https://www.youtube.com/embed/dUW39V9nASo";

  if (contentid.includes("r1")) return "https://www.youtube.com/embed/dUW39V9nASo";

  if (contentid.includes("f1")) return "https://www.youtube.com/embed/dUW39V9nASo";
}

function GetHeading() {
  let { contentid } = useParams();

  if (contentid.includes("g1"))
    return (
      <p>
        Angebot zur <br /> Zusammenarbeit
      </p>
    );

  if (contentid.includes("e1"))
    return (
      <p>
        Offer of collaboration <br /> by Represocial
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
        Предложение по сотрудничеству <br /> от Represocial
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
        Offre de collaboration <br /> de Represocial
      </p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
        Oferta de colaboración <br /> con Represocial
      </p>
    );
}

function GetText() {
  let { contentid } = useParams();

  if (contentid == "e1g2")
  return <p></p>;

  if (contentid == "e1g2")
  return <p></p>;

}

function GetTitle() {
  let { contentid } = useParams();

  if (contentid.includes("e1"))
    return (
      <p>
        What is Represocial?
      </p>
    );

    if (contentid.includes("g1"))
    return (
      <p>
        Was ist Represocial?
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
        Qu'est-ce que Represocial?
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
        Что такое Represocial?
      </p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
¿Qué es Represocial?
      </p>
    );
}

function GetText2() {
  let { contentid } = useParams();

  if (contentid == "e1")
    return (
      <p>
        Represocial is the most simple, comfortable & reliable way to organize social media raffles & giveaways (= raffle in the context of a sneaker raffle, not a paid giveaway). Represocial is a platform that we created to make <b>raffles & giveaways across different social media platforms</b> as easy and effective as possible, so you can achieve the<b> best possible results</b> with your giveaways. You can run them on our platform and with that, integrate all your social media channels into your raffles & giveaways, so they are <b>not limited to one platform</b>, allowing you to use your reach as effective as possible.
      </p>
    );

    // classic giveaways

  if (contentid == "e1g2")
    return (
      <p>
      Represocial is the <b>most simple, comfortable & reliable way</b> to <b>organize social media giveaways</b>, and it is completely <b>free</b>. <br></br>

<br></br>Represocial is a platform that we created to make <b>giveaways across different social media platforms</b> as easy and effective as possible, so you can achieve the<b> best possible results</b> with your giveaways. <br></br><br></br>You can run them on our platform and by doing that, integrate all your social media channels into your giveaways, so they are <b>not limited to one platform</b>, allowing you to use your reach as effective as possible. Also with Represocial, you can easily <b>collaborate</b> on giveaways with others, promoting each other and managing (and announcing winners of) your giveaway in one central place.
    </p>
    );

    if (contentid == "s1g2")
    return (
      <p>
        Represocial es la manera más simple, confortable y fiable de organizar sorteos en las redes sociales. Represocial es una plataforma que hemos creado para que los <b>sorteos en las diferentes redes sociales</b> sean lo más fáciles y efectivos posible. Puedes realizarlos en nuestra plataforma y integrar todos tus canales de redes sociales en tus sorteos, de manera que no se limiten a una sola plataforma, permitiéndote utilizar tu alcance de la manera más efectiva posible.</p>
    );

    if (contentid == "f1g2")
    return (
      <p>
       Represocial est le moyen le plus simple, confortable et fiable d'organiser des concours sur les médias sociaux. Represocial est une plateforme que nous avons crée pour faciliter et rendre aussi efficace que possible pour <b>les concours sur les différentes plateformes de médias sociaux</b>. Vous pouvez les organiser sur notre plateforme et avec cela, intégrer tous vos canaux de médias sociaux dans vos concours, de sorte qu'ils ne sont pas limités à une plateforme, vous permettant d'utiliser votre portée aussi efficacement que possible.</p>
    );

    if (contentid == "r1g2")
    return (
      <p>
       Represocial - это самый простой, удобный и надежный способ организации розыгрышей в социальных сетях. Represocial - это платформа, которую мы создали для того, чтобы сделать <b>розыгрыши  на различных платформах</b> социальных сетей максимально простыми и эффективными. Вы можете проводить их на нашей платформе и интегрировать все ваши каналы социальных сетей в ваши розыгрыши, чтобы они <b>не ограничивались одной платформой</b>, позволяя вам максимально эффективно использовать свой охват.</p>
    );

    if (contentid == "g1g2")
    return (
      <p>
        Represocial ist der <b>aller einfachste, bequemste & verlässlichste Weg</b> um Social Media Gewinnspiele durchzuführen. Represocial ist eine Platform die wir geschaffen haben, um <b>Gewinnspiele zwischen verschiedenen Social Medias zu ermöglichen und zu verbessern</b>, und diese so einfach und effektiv wie möglich zu gestalten. Man kann sie auf einer Platform organisieren, und dadurch direkt <b>alle Social Medias einbinden</b>, damit das Gewinnspiel nicht auf eine Platform beschränkt ist, und man seine <b>Reichweite so effektiv wie möglich</b> nutzen kann.
      </p>
    );

    // collab giveaways

    if (contentid == "e1cg2")
    return (
      <p>
        Represocial is the <b>most simple, comfortable & reliable way</b> to <b>organize social media giveaways</b>, and it is completely <b>free</b>. <br></br>

<br></br>Represocial is a platform that we created to make <b>giveaways across different social media platforms</b> as easy and effective as possible, so you can achieve the<b> best possible results</b> with your giveaways. <br></br><br></br>You can run them on our platform and by doing that, integrate all your social media channels into your giveaways, so they are <b>not limited to one platform</b>, allowing you to use your reach as effective as possible. Also with Represocial, you can easily <b>collaborate</b> on giveaways with others, promoting each other and managing (and announcing winners of) your giveaway in one central place.
      </p>
    );

    if (contentid == "s1cg2")
    return (
      <p>
        Represocial es la manera más simple, confortable y fiable de organizar sorteos en las redes sociales. Represocial es una plataforma que hemos creado para que los <b>sorteos en las diferentes redes sociales</b> sean lo más fáciles y efectivos posible. Puedes realizarlos en nuestra plataforma y integrar todos tus canales de redes sociales en tus sorteos, de manera que no se limiten a una sola plataforma, permitiéndote utilizar tu alcance de la manera más efectiva posible.</p>
    );

    if (contentid == "f1cg2")
    return (
      <p>
       Represocial est le moyen le plus simple, confortable et fiable d'organiser des concours sur les médias sociaux. Represocial est une plateforme que nous avons crée pour faciliter et rendre aussi efficace que possible pour <b>les concours sur les différentes plateformes de médias sociaux</b>. Vous pouvez les organiser sur notre plateforme et avec cela, intégrer tous vos canaux de médias sociaux dans vos concours, de sorte qu'ils ne sont pas limités à une plateforme, vous permettant d'utiliser votre portée aussi efficacement que possible.</p>
    );

    if (contentid == "r1cg2")
    return (
      <p>
       Represocial - это самый простой, удобный и надежный способ организации розыгрышей в социальных сетях. Represocial - это платформа, которую мы создали для того, чтобы сделать <b>розыгрыши  на различных платформах</b> социальных сетей максимально простыми и эффективными. Вы можете проводить их на нашей платформе и интегрировать все ваши каналы социальных сетей в ваши розыгрыши, чтобы они <b>не ограничивались одной платформой</b>, позволяя вам максимально эффективно использовать свой охват.</p>
    );

    if (contentid == "g1cg2")
    return (
      <p>
        Represocial ist der <b>aller einfachste, bequemste & verlässlichste Weg</b> um Social Media Gewinnspiele durchzuführen. Represocial ist eine Platform die wir geschaffen haben, um <b>Gewinnspiele zwischen verschiedenen Social Medias zu ermöglichen und zu verbessern</b>, und diese so einfach und effektiv wie möglich zu gestalten. Man kann sie auf einer Platform organisieren, und dadurch direkt <b>alle Social Medias einbinden</b>, damit das Gewinnspiel nicht auf eine Platform beschränkt ist, und man seine <b>Reichweite so effektiv wie möglich</b> nutzen kann.
      </p>
    );

    // raffles

    if (contentid == "e1r2")
    return (
      <p>
      Represocial is the <b>most simple, comfortable & reliable way</b> to <b>organize (sneaker) raffles</b> for small/medium-sized businesses, and it is completely <b>free</b><br></br>

<br></br>Represocial is a platform that we created to make <b>(sneaker) raffles across different social media platforms</b> as easy and effective as possible, so you can achieve the<b> best possible results</b> with your raffles and can <b>easily grow your social medias</b> when holding raffles. Also we have all necessary safety measures, to <b>prevent fraud</b> participants in your raffles. <br></br><br></br>
Represocial is a way better solution than Google Forms for example, because it is <b>natively designed</b> for <b>(sneaker) raffles</b> and has a very-well structured process for both, organizers and participants, because it is completely designed for that purpose.
    </p>
    );

    if (contentid == "s1r2")
    return (
      <p>
        Represocial es la manera más simple, confortable y fiable de organizar sorteos en las redes sociales. Represocial es una plataforma que hemos creado para que los <b>sorteos en las diferentes redes sociales</b> sean lo más fáciles y efectivos posible. Puedes realizarlos en nuestra plataforma y integrar todos tus canales de redes sociales en tus sorteos, de manera que no se limiten a una sola plataforma, permitiéndote utilizar tu alcance de la manera más efectiva posible.</p>
    );

    if (contentid == "f1r2")
    return (
      <p>
       Represocial est le moyen le plus simple, confortable et fiable d'organiser des concours sur les médias sociaux. Represocial est une plateforme que nous avons crée pour faciliter et rendre aussi efficace que possible pour <b>les concours sur les différentes plateformes de médias sociaux</b>. Vous pouvez les organiser sur notre plateforme et avec cela, intégrer tous vos canaux de médias sociaux dans vos concours, de sorte qu'ils ne sont pas limités à une plateforme, vous permettant d'utiliser votre portée aussi efficacement que possible.</p>
    );

    if (contentid == "r1r2")
    return (
      <p>
       Represocial - это самый простой, удобный и надежный способ организации розыгрышей в социальных сетях. Represocial - это платформа, которую мы создали для того, чтобы сделать <b>розыгрыши  на различных платформах</b> социальных сетей максимально простыми и эффективными. Вы можете проводить их на нашей платформе и интегрировать все ваши каналы социальных сетей в ваши розыгрыши, чтобы они <b>не ограничивались одной платформой</b>, позволяя вам максимально эффективно использовать свой охват.</p>
    );

    if (contentid == "g1r2")
    return (
      <p>
        Represocial ist der <b>aller einfachste, bequemste & verlässlichste Weg</b> um auf Social Media, (Sneaker-) Raffles durchzuführen. Represocial ist eine Platform die wir geschaffen haben, um <b>Raffles zu vereinfachen und verschiedenen Social Medias einzubinden</b>, und diese so einfach und effektiv wie möglich zu gestalten. Represocial ist eine gute Alternative zu Google Forms ect, weil man das gesamte Raffle ohne irgendwelche Mühen auf unserer Platform organisieren, und direkt <b>alle Social Medias einbinden kann</b>.
      </p>
    );


}



function GetTitle2() {
  /*
  let { contentid } = useParams();

  if (contentid.includes("e1g"))
    return (
      <p>
      The benefits of Represocial?
      </p>
    );

    if (contentid.includes("g1"))
    return (
      <p>
      Die Vorteile von Represocial?
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
      Les avantages de Represocial?
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
      Преимущества Represocial?
      </p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
      Los beneficios de Represocial?
      </p>
    );
    */
}



function GetText3() {
  /*
  let { contentid } = useParams();

  if (contentid == "e1")
  return (
    <div>
    <p>
The most benifital points of using Represocial are <br /><br /> </p>

<ul className="benefits-list">
<li> You can create and manage your raffle/giveaway in <b>one place</b>, and promote it across all your social medias channels with one link. </li>
<li>You get the possibility to <b>integrate all audiences of your social media channels</b> (for example to exchange existing followers across platforms, and right away bind new people to all your social medias) into your raffle/giveaway. <br /></li>
<li>You get the possibility to <b>use the full reach of all your social medias</b> to more effectively promote your raffles & giveaway (through one platform, you will automatically increase the reach of the whole raffle/giveaway on all the other platforms and promote all your social medias - and this applies for every platform). <br /></li>
<li> There are unlimited possiblites to run your raffles & giveaways, so for example you can <b>integrate affiliation and or all possible tasks</b> (visit shop, website ect) for your entrants <br /> </li>
<li>Automatic <b>legal compliance</b> through Represocial's Terms & Privacy Policy<br /> </li>
<li> <b>Staistics</b> about your raffles & giveaways performance<br /> </li>
<li> Possibility to <b>announce the winners</b> (Names or Instagram usernames) publicly with a trusted system (this is optional, you can keep them anonymous but it is good for transperancy and fairness)<br /> </li>
<li>Possibility to collect optional information about your winners that they have to provide when entering, like email adress, shipping adress ect. Also you can collect usernames for all major social media platforms.</li>
<li> <b>Earnings & promotion</b> possibility when collaborating with us </li></ul></div>
  );

  if (contentid == "e1g2")
    return (
      <div>
      <p>
The most benifital points of using Represocial are <br /><br /> </p>

<ul className="benefits-list">
<li> You can create and manage your giveaway in <b>one place</b>, and promote it across all your social medias channels with one link. </li>
<li>You get the possibility to <b>integrate all audiences of your social media channels</b> (for example to exchange existing followers across platforms, and right away bind new people to all your social medias) into your giveaway. <br /></li>
<li>You get the possibility to <b>use the full reach of all your social medias</b> to more effectively promote your giveaway (through one platform, you will automatically increase the reach of the whole giveaway on all the other platforms and promote all your social medias - and this applies for every platform). <br /></li>
<li> There are unlimited possiblites to run your giveaways, so for example you can <b>integrate affiliation and or all possible tasks</b> (visit shop, website ect) for your entrants <br /> </li>
<li>Automatic <b>legal compliance</b> through Represocial's Terms & Privacy Policy<br /> </li>
<li> <b>Staistics</b> about your giveaways performance<br /> </li>
<li> Possibility to <b>announce the winners</b> (Names or Instagram usernames) publicly with a trusted system (this is optional, you can keep them anonymous but it is good for transperancy and fairness)<br /> </li>
<li>Possibility to collect optional information about your winners that they have to provide when entering, like email adress, shipping adress ect. Also you can collect usernames for all major social media platforms.</li>
<li> <b>Earnings & promotion</b> possibility when collaborating with us </li></ul></div>
    );

    if (contentid == "f1g2")
    return (
      <div>
      <p>
      Les points les plus bénéfiques de l'utilisation de Represocial sont les suivants <br /><br /> </p>

<ul className="benefits-list">
<li> Vous pouvez créer et administrer votre concours en un seul endroit, et le promouvoir sur tous vos canaux de médias sociaux avec un seul lien. </li>
<li>Vous avez la possibilité d'intégrer <b>toutes les audiences de vos canaux de médias sociaux</b> (par exemple, pour échanger des adeptes existants entre les plateformes et lier immédiatement de nouvelles personnes à tous vos médias sociaux) dans votre concours. <br /></li>
<li>Vous avez la <b>possibilité d'utiliser toute la portée de tous vos médias sociaux</b> pour promouvoir plus efficacement votre concours (par le biais d'une seule plateforme, vous augmenterez automatiquement la portée de l'ensemble du concours sur toutes les autres plateformes et ferez la promotion de tous vos médias sociaux, et ce pour toutes les plateformes). <br /></li>
<li> Il existe un nombre <b>infini de possibilités pour organiser vos concours</b>, vous pouvez par exemple intégrer une affiliation ou toutes les tâches possibles (visite d'un magasin, d'un site web, etc.) pour vos participants. <br /> </li>
<li>Conformité juridique automatique grâce aux conditions générales et à la politique de confidentialité de Represocial.<br /> </li>
<li> <b>Statistiques</b> sur les performances de vos concours publicitaires.<br /> </li>
<li> Possibilité de <b>annoncer les gagnants</b> (Noms ou pseudos Instagram) publiquement avec un système de confiance (c'est optionnel, vous pouvez les garder anonymes mais c'est bien pour la transparence et l'équité).<br /> </li>
<li>Possibilité de collecter des informations optionnelles sur vos gagnants qu'ils doivent indiquer lors de leur inscription, comme l'adresse e-mail, l'adresse de livraison, etc. Vous pouvez aussi collecter des noms d'utilisateur pour toutes les principales plateformes de médias sociaux.</li>
<li> <b>Possibilité de gagner de l'argent et de bénéficier de promotions</b> en collaborant avec nous. </li></ul></div>
    );

    if (contentid == "r1g2")
    return (
      <div>
      <p>
Самые важные преимущества использования Represocial следующие <br /><br /> </p>

<ul className="benefits-list">
<li> Вы можете создавать и управлять розыгрыг в <b>одном месте</b>, а также продвигать его по всем своим каналам социальных сетей с помощью одной ссылки. </li>
<li>Вы получаете возможность <b>интегрировать всю аудиторию ваших каналов социальных сетей</b> (например, обмениваться существующими подписчиками между платформами и сразу же привязывать новых людей ко всем вашим социальным сетям) в свой розыгрыш. <br /></li>
<li>Вы получаете возможность <b>использовать полный охват всех ваших социальных сетей</b> для более эффективного продвижения вашего розыгрыша (используя одну платформу, вы автоматически увеличиваете охват всего розыгрыша на всех других платформах и продвигаете все ваши социальные сети - и это относится к каждой платформе). <br /></li>
<li> Возможности проведения розыгрышей неограниченны, например, вы можете <b>подключить партнёрские программы и или все возможные задания</b> (посетить магазин, веб-сайт и т.д.) для ваших участников. <br /> </li>
<li>Автоматическое <b>соответствие законодательству</b> с помощью Условий и Политики конфиденциальности Represocial<br /> </li>
<li> <b>Статистика</b> о результатах ваших конкрсах <br /> </li>
<li> Возможность <b>объявить победителей</b> (имена или имена пользователей Instagram) публично с помощью доверенной системы (это необязательно, вы можете оставить их анонимными, но это хорошо для прозрачности и справедливости).<br /> </li>
<li>Возможность собирать необязательную информацию о победителях, которую они должны предоставить при участии в розыгрыше, например, адрес электронной почты, адрес доставки и т.д. Также вы можете собирать имена пользователей для всех основных платформ социальных сетей.</li>
<li> <b>Возможность заработка и продвижения</b> при сотрудничестве с нами </li></ul></div>
    );

    if (contentid == "s1g2")
    return (
      <div>
      <p>
      Los puntos más beneficiosos de utilizar Represocial son <br /><br /> </p>

<ul className="benefits-list">
<li> Puedes crear y organizar tu sorteo en un solo lugar, y promocionarlo en todos tus canales de redes sociales con un solo enlace. </li>
<li>Tienes la posibilidad de <b>integrar todas las audiencias de tus canales de redes sociales</b> (por ejemplo, intercambiar los seguidores existentes entre plataformas, y enseguida vincular nuevas personas a todos tus medios sociales) en tu sorteo. <br /></li>
<li>Tienes la posibilidad de <b>utilizar todo el alcance de todos tus medios sociales</b> para promocionar más eficazmente tu sorteo (a través de una plataforma, aumentarás automáticamente el alcance de todo el sorteo en todas las demás plataformas y promocionarás todos tus medios sociales - y esto se aplica a todas las plataformas). <br /></li>
<li> Hay posibilidades infinitas para realizar tus sorteos, así por ejemplo puedes <b>integrar afiliación y o todas las tareas posibles</b> (visitar tienda, sitio web ect) para tus participantes <br /> </li>
<li>Cumplimiento legal a través de los términos y la política de privacidad de Represocial.<br /> </li>
<li> <b>Staistics</b> sobre sus sorteos<br /> </li>
<li> Posibilidad de <b>anunciar a los ganadores</b> (Nombres o nombres de usuario de Instagram) públicamente con un sistema de confianza (esto es opcional, puedes mantenerlos anónimos pero es bueno para la transperencia y la equidad)<br /> </li>
<li>Posibilidad de obtener información opcional sobre los ganadores que tienen que proporcionar al participar, como la dirección de correo electrónico, la dirección de envío, etc. También puedes recoger los nombres de usuario de las principales plataformas de redes sociales.</li>
<li> <b>Posibilidad de ganancias y promoción</b> cuando se colabora con nosotros </li></ul></div>
    );

    if (contentid == "g1g2")
    return (
      <div>
      <p>
Die größten Vorteile von Represocial sind<br /><br /> </p>

<ul className="benefits-list">
<li>Man kann das gesamte Gewinnspiel an <b>einem Platz</b> organisieren, und es mit einem Link auf allen Social Medias promoten. </li>
<li>Man kriegt die Möglichkeit <b>alle unterschiedelichen Zuschauerschaften einzubinden</b> (um z.b. Follower auszutauschen, oder neue Leute, von jeder Platform jeweils, direkt an alle anderen zu binden). <br /></li>
<li>Man kann <b>seine volle Reichweite</b> ausnutzen um das Gewinnspiel effektiver durchzuführen (die einzelnen Sozialen Netzwerke, pushen das Gewinnspiel gegenseitig hoch). <br /></li>
<li>Es gibt unendlich viele Gestaltungsmöglichkeiten. Man kann <b>Affiliaten Programme oder sonstige Aufgaben wie</b> (Shop, Website Besuch, Formular) erstellen. <br /> </li>
<li> <b>Statistiken</b> über die Performance vom Gewinnspiel<br /> </li>
<li> Möglichkeit die Gewinner <b>zu verkünden</b> (Namen oder Instagram Nutzernamen) und zu veröffentlichen (das ist optional, anonym geht auch, aber es ist gut für die Transparenz & Fairness)<br /> </li>
<li>Möglichkeit zusätliche Informationen zu sammeln wie Schuhgröße, Bevorzugter Preis, Email Adresse ect.</li>
<li> <b>Einname & Promotion</b> Möglichkeit bei direkter Zusammenarbeit mit uns </li></ul></div>
    );

    // collab giveaways
/*
    if (contentid == "e1cg2")
    return (
      <div>
      <p>
The most benifital points of using Represocial are <br /><br /> </p>

<ul className="benefits-list">
<li> You can create and manage your giveaway in <b>one place</b>, and promote it across all your social medias channels with one link. </li>
<li>You get the possibility to <b>integrate all audiences of your social media channels</b> (for example to exchange existing followers across platforms, and right away bind new people to all your social medias) into your giveaway. <br /></li>
<li>You get the possibility to <b>use the full reach of all your social medias</b> to more effectively promote your giveaway (through one platform, you will automatically increase the reach of the whole giveaway on all the other platforms and promote all your social medias - and this applies for every platform). <br /></li>
<li> There are unlimited possiblites to run your giveaways, so for example you can <b>integrate affiliation and or all possible tasks</b> (visit shop, website ect) for your entrants <br /> </li>
<li>Automatic <b>legal compliance</b> through Represocial's Terms & Privacy Policy<br /> </li>
<li> <b>Staistics</b> about your giveaways performance<br /> </li>
<li> Possibility to <b>announce the winners</b> (Names or Instagram usernames) publicly with a trusted system (this is optional, you can keep them anonymous but it is good for transperancy and fairness)<br /> </li>
<li>Possibility to collect optional information about your winners that they have to provide when entering, like email adress, shipping adress ect. Also you can collect usernames for all major social media platforms.</li>
<li> <b>Earnings & promotion</b> possibility when collaborating with us </li></ul></div>
    );

    if (contentid == "f1cg2")
    return (
      <div>
      <p>
      Les points les plus bénéfiques de l'utilisation de Represocial sont les suivants <br /><br /> </p>

<ul className="benefits-list">
<li> Vous pouvez créer et administrer votre concours en un seul endroit, et le promouvoir sur tous vos canaux de médias sociaux avec un seul lien. </li>
<li>Vous avez la possibilité d'intégrer <b>toutes les audiences de vos canaux de médias sociaux</b> (par exemple, pour échanger des adeptes existants entre les plateformes et lier immédiatement de nouvelles personnes à tous vos médias sociaux) dans votre concours. <br /></li>
<li>Vous avez la <b>possibilité d'utiliser toute la portée de tous vos médias sociaux</b> pour promouvoir plus efficacement votre concours (par le biais d'une seule plateforme, vous augmenterez automatiquement la portée de l'ensemble du concours sur toutes les autres plateformes et ferez la promotion de tous vos médias sociaux, et ce pour toutes les plateformes). <br /></li>
<li> Il existe un nombre <b>infini de possibilités pour organiser vos concours</b>, vous pouvez par exemple intégrer une affiliation ou toutes les tâches possibles (visite d'un magasin, d'un site web, etc.) pour vos participants. <br /> </li>
<li>Conformité juridique automatique grâce aux conditions générales et à la politique de confidentialité de Represocial.<br /> </li>
<li> <b>Statistiques</b> sur les performances de vos concours publicitaires.<br /> </li>
<li> Possibilité de <b>annoncer les gagnants</b> (Noms ou pseudos Instagram) publiquement avec un système de confiance (c'est optionnel, vous pouvez les garder anonymes mais c'est bien pour la transparence et l'équité).<br /> </li>
<li>Possibilité de collecter des informations optionnelles sur vos gagnants qu'ils doivent indiquer lors de leur inscription, comme l'adresse e-mail, l'adresse de livraison, etc. Vous pouvez aussi collecter des noms d'utilisateur pour toutes les principales plateformes de médias sociaux.</li>
<li> <b>Possibilité de gagner de l'argent et de bénéficier de promotions</b> en collaborant avec nous. </li></ul></div>
    );

    if (contentid == "r1cg2")
    return (
      <div>
      <p>
Самые важные преимущества использования Represocial следующие <br /><br /> </p>

<ul className="benefits-list">
<li> Вы можете создавать и управлять розыгрыг в <b>одном месте</b>, а также продвигать его по всем своим каналам социальных сетей с помощью одной ссылки. </li>
<li>Вы получаете возможность <b>интегрировать всю аудиторию ваших каналов социальных сетей</b> (например, обмениваться существующими подписчиками между платформами и сразу же привязывать новых людей ко всем вашим социальным сетям) в свой розыгрыш. <br /></li>
<li>Вы получаете возможность <b>использовать полный охват всех ваших социальных сетей</b> для более эффективного продвижения вашего розыгрыша (используя одну платформу, вы автоматически увеличиваете охват всего розыгрыша на всех других платформах и продвигаете все ваши социальные сети - и это относится к каждой платформе). <br /></li>
<li> Возможности проведения розыгрышей неограниченны, например, вы можете <b>подключить партнёрские программы и или все возможные задания</b> (посетить магазин, веб-сайт и т.д.) для ваших участников. <br /> </li>
<li>Автоматическое <b>соответствие законодательству</b> с помощью Условий и Политики конфиденциальности Represocial<br /> </li>
<li> <b>Статистика</b> о результатах ваших конкрсах <br /> </li>
<li> Возможность <b>объявить победителей</b> (имена или имена пользователей Instagram) публично с помощью доверенной системы (это необязательно, вы можете оставить их анонимными, но это хорошо для прозрачности и справедливости).<br /> </li>
<li>Возможность собирать необязательную информацию о победителях, которую они должны предоставить при участии в розыгрыше, например, адрес электронной почты, адрес доставки и т.д. Также вы можете собирать имена пользователей для всех основных платформ социальных сетей.</li>
<li> <b>Возможность заработка и продвижения</b> при сотрудничестве с нами </li></ul></div>
    );

    if (contentid == "s1cg2")
    return (
      <div>
      <p>
      Los puntos más beneficiosos de utilizar Represocial son <br /><br /> </p>

<ul className="benefits-list">
<li> Puedes crear y organizar tu sorteo en un solo lugar, y promocionarlo en todos tus canales de redes sociales con un solo enlace. </li>
<li>Tienes la posibilidad de <b>integrar todas las audiencias de tus canales de redes sociales</b> (por ejemplo, intercambiar los seguidores existentes entre plataformas, y enseguida vincular nuevas personas a todos tus medios sociales) en tu sorteo. <br /></li>
<li>Tienes la posibilidad de <b>utilizar todo el alcance de todos tus medios sociales</b> para promocionar más eficazmente tu sorteo (a través de una plataforma, aumentarás automáticamente el alcance de todo el sorteo en todas las demás plataformas y promocionarás todos tus medios sociales - y esto se aplica a todas las plataformas). <br /></li>
<li> Hay posibilidades infinitas para realizar tus sorteos, así por ejemplo puedes <b>integrar afiliación y o todas las tareas posibles</b> (visitar tienda, sitio web ect) para tus participantes <br /> </li>
<li>Cumplimiento legal a través de los términos y la política de privacidad de Represocial.<br /> </li>
<li> <b>Staistics</b> sobre sus sorteos<br /> </li>
<li> Posibilidad de <b>anunciar a los ganadores</b> (Nombres o nombres de usuario de Instagram) públicamente con un sistema de confianza (esto es opcional, puedes mantenerlos anónimos pero es bueno para la transperencia y la equidad)<br /> </li>
<li>Posibilidad de obtener información opcional sobre los ganadores que tienen que proporcionar al participar, como la dirección de correo electrónico, la dirección de envío, etc. También puedes recoger los nombres de usuario de las principales plataformas de redes sociales.</li>
<li> <b>Posibilidad de ganancias y promoción</b> cuando se colabora con nosotros </li></ul></div>
    );

    if (contentid == "g1cg2")
    return (
      <div>
      <p>
Die größten Vorteile von Represocial sind<br /><br /> </p>

<ul className="benefits-list">
<li>Man kann das gesamte Gewinnspiel an <b>einem Platz</b> organisieren, und es mit einem Link auf allen Social Medias promoten. </li>
<li>Man kriegt die Möglichkeit <b>alle unterschiedelichen Zuschauerschaften einzubinden</b> (um z.b. Follower auszutauschen, oder neue Leute, von jeder Platform jeweils, direkt an alle anderen zu binden). <br /></li>
<li>Man kann <b>seine volle Reichweite</b> ausnutzen um das Gewinnspiel effektiver durchzuführen (die einzelnen Sozialen Netzwerke, pushen das Gewinnspiel gegenseitig hoch). <br /></li>
<li>Es gibt unendlich viele Gestaltungsmöglichkeiten. Man kann <b>Affiliaten Programme oder sonstige Aufgaben wie</b> (Shop, Website Besuch, Formular) erstellen. <br /> </li>
<li> <b>Statistiken</b> über die Performance vom Gewinnspiel<br /> </li>
<li> Möglichkeit die Gewinner <b>zu verkünden</b> (Namen oder Instagram Nutzernamen) und zu veröffentlichen (das ist optional, anonym geht auch, aber es ist gut für die Transparenz & Fairness)<br /> </li>
<li>Möglichkeit zusätliche Informationen zu sammeln wie Schuhgröße, Bevorzugter Preis, Email Adresse ect.</li>
<li> <b>Einname & Promotion</b> Möglichkeit bei direkter Zusammenarbeit mit uns </li></ul></div>
    );


    // raffles

    if (contentid == "e1r2")
    return (
      <div>
      <p>
The most benifital points of using Represocial are <br /><br /> </p>

<ul className="benefits-list">
<li> You can create and manage your giveaway in <b>one place</b>, and promote it across all your social medias channels with one link. </li>
<li>You get the possibility to <b>integrate all audiences of your social media channels</b> (for example to exchange existing followers across platforms, and right away bind new people to all your social medias) into your giveaway. <br /></li>
<li>You get the possibility to <b>use the full reach of all your social medias</b> to more effectively promote your giveaway (through one platform, you will automatically increase the reach of the whole giveaway on all the other platforms and promote all your social medias - and this applies for every platform). <br /></li>
<li> There are unlimited possiblites to run your giveaways, so for example you can <b>integrate affiliation and or all possible tasks</b> (visit shop, website ect) for your entrants <br /> </li>
<li>Automatic <b>legal compliance</b> through Represocial's Terms & Privacy Policy<br /> </li>
<li> <b>Staistics</b> about your giveaways performance<br /> </li>
<li> Possibility to <b>announce the winners</b> (Names or Instagram usernames) publicly with a trusted system (this is optional, you can keep them anonymous but it is good for transperancy and fairness)<br /> </li>
<li>Possibility to collect optional information about your winners that they have to provide when entering, like email adress, shipping adress ect. Also you can collect usernames for all major social media platforms.</li>
<li> <b>Earnings & promotion</b> possibility when collaborating with us </li></ul></div>
    );

    if (contentid == "f1r2")
    return (
      <div>
      <p>
      Les points les plus bénéfiques de l'utilisation de Represocial sont les suivants <br /><br /> </p>

<ul className="benefits-list">
<li> Vous pouvez créer et administrer votre concours en un seul endroit, et le promouvoir sur tous vos canaux de médias sociaux avec un seul lien. </li>
<li>Vous avez la possibilité d'intégrer <b>toutes les audiences de vos canaux de médias sociaux</b> (par exemple, pour échanger des adeptes existants entre les plateformes et lier immédiatement de nouvelles personnes à tous vos médias sociaux) dans votre concours. <br /></li>
<li>Vous avez la <b>possibilité d'utiliser toute la portée de tous vos médias sociaux</b> pour promouvoir plus efficacement votre concours (par le biais d'une seule plateforme, vous augmenterez automatiquement la portée de l'ensemble du concours sur toutes les autres plateformes et ferez la promotion de tous vos médias sociaux, et ce pour toutes les plateformes). <br /></li>
<li> Il existe un nombre <b>infini de possibilités pour organiser vos concours</b>, vous pouvez par exemple intégrer une affiliation ou toutes les tâches possibles (visite d'un magasin, d'un site web, etc.) pour vos participants. <br /> </li>
<li>Conformité juridique automatique grâce aux conditions générales et à la politique de confidentialité de Represocial.<br /> </li>
<li> <b>Statistiques</b> sur les performances de vos concours publicitaires.<br /> </li>
<li> Possibilité de <b>annoncer les gagnants</b> (Noms ou pseudos Instagram) publiquement avec un système de confiance (c'est optionnel, vous pouvez les garder anonymes mais c'est bien pour la transparence et l'équité).<br /> </li>
<li>Possibilité de collecter des informations optionnelles sur vos gagnants qu'ils doivent indiquer lors de leur inscription, comme l'adresse e-mail, l'adresse de livraison, etc. Vous pouvez aussi collecter des noms d'utilisateur pour toutes les principales plateformes de médias sociaux.</li>
<li> <b>Possibilité de gagner de l'argent et de bénéficier de promotions</b> en collaborant avec nous. </li></ul></div>
    );

    if (contentid == "r1r2")
    return (
      <div>
      <p>
Самые важные преимущества использования Represocial следующие <br /><br /> </p>

<ul className="benefits-list">
<li> Вы можете создавать и управлять розыгрыг в <b>одном месте</b>, а также продвигать его по всем своим каналам социальных сетей с помощью одной ссылки. </li>
<li>Вы получаете возможность <b>интегрировать всю аудиторию ваших каналов социальных сетей</b> (например, обмениваться существующими подписчиками между платформами и сразу же привязывать новых людей ко всем вашим социальным сетям) в свой розыгрыш. <br /></li>
<li>Вы получаете возможность <b>использовать полный охват всех ваших социальных сетей</b> для более эффективного продвижения вашего розыгрыша (используя одну платформу, вы автоматически увеличиваете охват всего розыгрыша на всех других платформах и продвигаете все ваши социальные сети - и это относится к каждой платформе). <br /></li>
<li> Возможности проведения розыгрышей неограниченны, например, вы можете <b>подключить партнёрские программы и или все возможные задания</b> (посетить магазин, веб-сайт и т.д.) для ваших участников. <br /> </li>
<li>Автоматическое <b>соответствие законодательству</b> с помощью Условий и Политики конфиденциальности Represocial<br /> </li>
<li> <b>Статистика</b> о результатах ваших конкрсах <br /> </li>
<li> Возможность <b>объявить победителей</b> (имена или имена пользователей Instagram) публично с помощью доверенной системы (это необязательно, вы можете оставить их анонимными, но это хорошо для прозрачности и справедливости).<br /> </li>
<li>Возможность собирать необязательную информацию о победителях, которую они должны предоставить при участии в розыгрыше, например, адрес электронной почты, адрес доставки и т.д. Также вы можете собирать имена пользователей для всех основных платформ социальных сетей.</li>
<li> <b>Возможность заработка и продвижения</b> при сотрудничестве с нами </li></ul></div>
    );

    if (contentid == "s1r2")
    return (
      <div>
      <p>
      Los puntos más beneficiosos de utilizar Represocial son <br /><br /> </p>

<ul className="benefits-list">
<li> Puedes crear y organizar tu sorteo en un solo lugar, y promocionarlo en todos tus canales de redes sociales con un solo enlace. </li>
<li>Tienes la posibilidad de <b>integrar todas las audiencias de tus canales de redes sociales</b> (por ejemplo, intercambiar los seguidores existentes entre plataformas, y enseguida vincular nuevas personas a todos tus medios sociales) en tu sorteo. <br /></li>
<li>Tienes la posibilidad de <b>utilizar todo el alcance de todos tus medios sociales</b> para promocionar más eficazmente tu sorteo (a través de una plataforma, aumentarás automáticamente el alcance de todo el sorteo en todas las demás plataformas y promocionarás todos tus medios sociales - y esto se aplica a todas las plataformas). <br /></li>
<li> Hay posibilidades infinitas para realizar tus sorteos, así por ejemplo puedes <b>integrar afiliación y o todas las tareas posibles</b> (visitar tienda, sitio web ect) para tus participantes <br /> </li>
<li>Cumplimiento legal a través de los términos y la política de privacidad de Represocial.<br /> </li>
<li> <b>Staistics</b> sobre sus sorteos<br /> </li>
<li> Posibilidad de <b>anunciar a los ganadores</b> (Nombres o nombres de usuario de Instagram) públicamente con un sistema de confianza (esto es opcional, puedes mantenerlos anónimos pero es bueno para la transperencia y la equidad)<br /> </li>
<li>Posibilidad de obtener información opcional sobre los ganadores que tienen que proporcionar al participar, como la dirección de correo electrónico, la dirección de envío, etc. También puedes recoger los nombres de usuario de las principales plataformas de redes sociales.</li>
<li> <b>Posibilidad de ganancias y promoción</b> cuando se colabora con nosotros </li></ul></div>
    );

    if (contentid == "g1r2")
    return (
      <div>
      <p>
Die größten Vorteile von Represocial sind<br /><br /> </p>

<ul className="benefits-list">
<li>Man kann das gesamte Raffle an <b>einem Platz</b> organisieren, und es mit einem Link auf allen Social Medias promoten. </li>
<li>Represocial ist auf Raffles ausgelegt. Es bietet einen guten Flow für die Teilnehmer der Raffles und ist sehr verständlich in seiner Bedienung.</li>
<li>Man kann das Raffle auf Represocial sehr gut anpassen. Teilnahmebedingungen, Bilder, Zeitraum der Durchführung und co.</li>
<li>Man kann <b>seine volle Reichweite</b> ausnutzen um das Raffle effektiver durchzuführen (die einzelnen Sozialen Netzwerke, pushen das Raffle gegenseitig hoch). <br /></li>
<li>Es gibt unendlich viele Gestaltungsmöglichkeiten. Man kann <b>Affiliaten Programme oder sonstige Aufgaben wie</b> (Shop, Website Besuch, Formular) erstellen. <br /> </li>
<li> <b>Statistiken</b> über die Performance vom Raffle<br /> </li>
<li> Möglichkeit die Gewinner <b>zu verkünden</b> (Namen oder Instagram Nutzernamen) und zu veröffentlichen (das ist optional, anonym geht auch, aber es ist gut für die Transparenz & Fairness)<br /> </li>
<li> <b>Einname & Promotion</b> Möglichkeit bei direkter Zusammenarbeit mit uns </li></ul></div>
    );
*/

}



function GetTitle3() {
  let { contentid } = useParams();

  if (contentid.includes("e1"))
    return (
      <p>
      Our offer to you
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
      Notre offre à vous
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
      Наше предложение для вас
      </p>
    );

    if (contentid.includes("g1"))
    return (
      <p>
      Unser Angebot an dich
      </p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
      Nuestra oferta para usted
      </p>
    );

}

function GetText4() {
  let { contentid } = useParams();

  if (contentid == "e1")
  return (
    <p>
If you are reading this after receiving an email or a direct message from us, <b>we are interested in directly working with you</b>. Besides all the benefits that Represocial has itself, we want to offer you a collaboration with opportunities of <b>earning money</b> through using Represocial & affilation and also <b>promotion</b> of your raffle/giveaway by Represocial. <br /><br />  We would pay you to use Represocial and also offer you money for everybody who signs up and uses Represocial after seeing your raffle/giveaway. <br /> <br /> We would promote your raffle/giveaway with posts, stories and Reels on all our social medias, though since we are just starting, we don't have a big reach to offer, so we'll try to compensate with first sponsored and viral posts.
</p>
  );

  // classic giveaways 

  if (contentid == "e1g2")
    return (
<p>

We would like to invite you to <b>host a giveaway on our platform Represocial</b> and directly collaborate with us. You can try out and <b>enjoy all benefits of our platform</b> and also get additional benefits such as <b>money and promotion from us</b>.

<br></br>

<br></br>
<span class="sub-heading">Better Results</span>
With Represocial you can achieve <b>way better results</b> for your giveaway campaigns, through including all your social medias into your giveaway. With Represocial you can <b>promote all your social medias</b> in the giveaway campaign, and through one simple link, you can share and promote the giveaway on all your platforms. By doing that you <b>grow and exchange your audiences</b> across different platforms, and automatically promote the campaign on each platform, even more.

<br></br>

<br></br>

<div className="offer-icon"><i class="fas fa-hand-holding-usd"></i></div>

<br></br>
<span class="sub-heading">Money and Earnings</span>

We would <b>pay you money</b> to try out Represocial on a CPM basis. We would also <b>pay you the same money</b> (also on a CPM basis), for anybody who signs up and uses Represocial after seeing your campaign or for anybody who you can <b>directly refer</b> to us. And also for all people who sign up or are referred through those people. 

<br></br>

<br></br>

<div className="offer-icon"><div className="socials"><i class="fab fa-instagram"></i> <i class="fab fa-tiktok"></i> <i class="fab fa-youtube"></i></div></div>

<br></br>
<span class="sub-heading">Promotion</span>


Also additionally we would like to <b>promote your giveaway</b> campaign on all our social media channels like Instagram (Stories, Posts, Reels), TikTok (Posts), Twitter (Fleets, Posts), Reddit (Posts), YouTube (Videos, Shorts) ect.

<br></br>

<br></br>

<div className="offer-icon">🤝</div>

<br></br>
<span class="sub-heading">Collaborative Giveaway</span>


There is also the <b>option of a collaborative giveaway</b> on Represocial. You and other channels with the <b>same reach and topics</b> as you (if you like, you can also invite/host it with friends or people you know), would host a giveaway on Represocial and promote each other. Represocial will completely <b>upfront sponsor</b> the prize for this giveaway and we would discuss and choose the prize together.

</p>
    );

    if (contentid == "r1g2")
    return (
      <p>
Если вы читаете это, получив от нас письмо или сообщение в директ, <b>мы заинтересованы в непосредственном сотрудничестве с вами</b>. Помимо всех преимуществ, которые дает Represocial, мы хотим предложить вам сотрудничество с возможностью <b>заработать</b> на использовании Represocial и аффилиации, а также <b>продвижении</b> ваших раздач с помощью Represocial. <br /><br /> Мы <b>заплатим вам за использование Represocial</b>, а также предложим вам деньги за каждого, кто зарегистрируется и будет использовать Represocial после того, как увидит вашу раздачу. <br /> <br /> Мы будем <b>продвигать ваш розыгрыш с помощью постов, историй и роликов</b> во всех наших социальных сетях, хотя, поскольку мы только начинаем, у нас нет большого охвата, поэтому мы постараемся компенсировать это первыми спонсорскими и вирусными постами. <br /> <br /> Возможно, мы также сможем <b>спонсировать приз для вашего следующего розыгрыша</b>, но мы должны обсудить это с вами лично.

</p>
    );

    if (contentid == "f1g2")
    return (
      <p>
Si vous lisez ce message après avoir recu un email ou un message direct de notre part, <b>nous sommes intéressés à collaborer directement avec vous</b>. En plus de tous les avantages que Represocial a lui-même, nous voulons vous offrir une collaboration avec des opportunités de <b>gagner de l'argent</b> en utilisant Represocial & l'affilation et aussi la <b>promotion</b> de votre concours par Represocial. <br /><br />Nous vous <b>payerons pour utiliser Represocial</b> et nous vous offrirons également de l'argent pour chaque personne qui s'inscrit et utilise Represocial après avoir vu votre concours. <br /> <br /> Nous <b>ferions la promotion de votre giveaway avec des posts, des histoires et des Reels</b> sur tous nos médias sociaux, bien que comme nous commençons tout juste, nous n'avons pas une grande portée à offrir, donc nous allons essayer de compenser avec des premiers posts sponsorisés et viraux. <br /> <br />  Nous pourrions aussi être en mesure de <b>sponsoriser un prix pour votre prochain concours</b>, mais nous devons en discuter directement avec vous.
   </p>
   );

    if (contentid == "s1g2")
    return (
      <p>
Si estás leyendo esto después de recibir un correo electrónico o un mensaje directo de nosotros, <b>estamos interesados en trabajar directamente contigo</b>. Además de todos los beneficios que tiene Represocial en sí mismo, queremos ofrecerle una colaboración con oportunidades de <b>ganar dinero</b> a través del uso de Represocial & affilation y también <b>promoción</b> de su sorteo por Represocial. <br /><br /> Te <b>pagaríamos por usar Represocial</b> y también te ofreceríamos dinero por todos los que se registren y usen Represocial después de ver tu sorteo. <br /> <br /> <b>Promocionaríamos tu sorteo con posts, historias y Reels</b> en todas nuestras redes sociales, aunque como estamos empezando, no tenemos un gran alcance que ofrecer, así que intentaremos compensar con primeros posts patrocinados y virales. <br /> <br />  También podemos financiar un premio para su próximo sorteo, pero tenemos que hablarlo directamente con usted.
</p>
    );

    if (contentid == "g1g2")
    return (
      <p>
  Falls sie diese Seite anschauen, nachdem sie eine Email oder Direktnachricht von uns erhalten haben, <b>sind wir sehr interessiert mit ihnen zusammenzuarbeiten</b>. Neben allen Vorteilen von Represocial, möchten wir ihnen noch eine <b>Einnahme Möglichkeit bieten</b>, durch die Nutzung von Represocial & Affilate und zusätzliche <b>Promotion</b> des Gewinnspiels durch Represocial. <br /><br />  Wir würden sie <b>für Nutzung von Represocial bezahlen</b> und auch für jeden neuen aktiven Nutzer zahlen, der Represocial für seine Kampagnen nutzt, nachdem er durch sie Represocial entdeckt hat. <br /> <br /> Wir würden ihr Gewinnspiel auch <b>auf allen unseren Social Medias promoten</b>, mithilfe von Posts, Stories, Reels. Da wir noch keine große Reichweite haben, werden wir diese zunächst mit gesponsorten und viralen Posts kompensieren. <br /> <br /> Eventuell können wir auch <b>einen Preis für Ihr nächstes Gewinnspiel</b> sponsern, aber das müssen wir direkt mit Ihnen besprechen.

  </p>
    );

      // collab giveaways 
  
  if (contentid == "e1cg2")
  return (
    <p>
We would like to invite you to <b>be part of a collaborative giveaway</b> on our platform Represocial.
<br></br>
<br></br>
<span class="sub-heading">Promotion and Growth</span>

You and other channels that have the same topic as you and approximately have the same reach as your channel will try out Represocial for this collaborative giveaway & <b>promote each others social medias</b>. 
<br></br>
<br></br>

<div className="offer-icon">🤝</div>

<br></br>

If you wish, you can <b>choose the people that you want to collab with</b> on this giveaway and also <b>invite other influencers/friends</b> to join. If not, <b>we will find/or already have other people (influencers, brands) with your reach & topics</b>, who would collaborate on this giveaway.

<br></br>
<br></br>

<div className="offer-icon"><i class="fas fa-hand-holding-usd"></i></div>

<br></br>
<span class="sub-heading">Sponsorship and Money</span>



Represocial will completely <b>upfront sponsor the prize</b> for this giveaway. We will discuss and choose the prize together. The prize would be matching to your channels topics, and will be of <b>high quality</b>. For example a pair of <b>AirPods (Pro), a perfume like Dior Sauvage, Nintendo Switch</b> or something else.
<br></br><br></br>Also after the campaigns end, <b>we might be able to pay</b> you some money, but we have to discuss that further. However it is always possible to <b>earn additional money</b> through our Affiliate program and through referring other people who could collaborate with us.
<br></br>
<br></br>

<div className="offer-icon"><div className="socials"><i class="fab fa-instagram"></i> <i class="fab fa-tiktok"></i> <i class="fab fa-youtube"></i></div></div>
<br></br>
<span class="sub-heading">Additional Promotion</span>

Represocial will also additionally <b>promote</b> the giveaway on all it's social medias, through posts, reels/tiktoks, stories ect.
</p>
  );

  if (contentid == "r1cg2")
  return (
    <p>
Если вы читаете это, получив от нас письмо или сообщение в директ, <b>мы заинтересованы в непосредственном сотрудничестве с вами</b>. Помимо всех преимуществ, которые дает Represocial, мы хотим предложить вам сотрудничество с возможностью <b>заработать</b> на использовании Represocial и аффилиации, а также <b>продвижении</b> ваших раздач с помощью Represocial. <br /><br /> Мы <b>заплатим вам за использование Represocial</b>, а также предложим вам деньги за каждого, кто зарегистрируется и будет использовать Represocial после того, как увидит вашу раздачу. <br /> <br /> Мы будем <b>продвигать ваш розыгрыш с помощью постов, историй и роликов</b> во всех наших социальных сетях, хотя, поскольку мы только начинаем, у нас нет большого охвата, поэтому мы постараемся компенсировать это первыми спонсорскими и вирусными постами. <br /> <br /> Возможно, мы также сможем <b>спонсировать приз для вашего следующего розыгрыша</b>, но мы должны обсудить это с вами лично.

</p>
  );

  if (contentid == "f1cg2")
  return (
    <p>
Si vous lisez ce message après avoir recu un email ou un message direct de notre part, <b>nous sommes intéressés à collaborer directement avec vous</b>. En plus de tous les avantages que Represocial a lui-même, nous voulons vous offrir une collaboration avec des opportunités de <b>gagner de l'argent</b> en utilisant Represocial & l'affilation et aussi la <b>promotion</b> de votre concours par Represocial. <br /><br />Nous vous <b>payerons pour utiliser Represocial</b> et nous vous offrirons également de l'argent pour chaque personne qui s'inscrit et utilise Represocial après avoir vu votre concours. <br /> <br /> Nous <b>ferions la promotion de votre giveaway avec des posts, des histoires et des Reels</b> sur tous nos médias sociaux, bien que comme nous commençons tout juste, nous n'avons pas une grande portée à offrir, donc nous allons essayer de compenser avec des premiers posts sponsorisés et viraux. <br /> <br />  Nous pourrions aussi être en mesure de <b>sponsoriser un prix pour votre prochain concours</b>, mais nous devons en discuter directement avec vous.
 </p>
 );

  if (contentid == "s1cg2")
  return (
    <p>
Si estás leyendo esto después de recibir un correo electrónico o un mensaje directo de nosotros, <b>estamos interesados en trabajar directamente contigo</b>. Además de todos los beneficios que tiene Represocial en sí mismo, queremos ofrecerle una colaboración con oportunidades de <b>ganar dinero</b> a través del uso de Represocial & affilation y también <b>promoción</b> de su sorteo por Represocial. <br /><br /> Te <b>pagaríamos por usar Represocial</b> y también te ofreceríamos dinero por todos los que se registren y usen Represocial después de ver tu sorteo. <br /> <br /> <b>Promocionaríamos tu sorteo con posts, historias y Reels</b> en todas nuestras redes sociales, aunque como estamos empezando, no tenemos un gran alcance que ofrecer, así que intentaremos compensar con primeros posts patrocinados y virales. <br /> <br />  También podemos financiar un premio para su próximo sorteo, pero tenemos que hablarlo directamente con usted.
</p>
  );

  if (contentid == "g1cg2")
  return (
    <p>
Falls sie diese Seite anschauen, nachdem sie eine Email oder Direktnachricht von uns erhalten haben, <b>sind wir sehr interessiert mit ihnen zusammenzuarbeiten</b>. Neben allen Vorteilen von Represocial, möchten wir ihnen noch eine <b>Einnahme Möglichkeit bieten</b>, durch die Nutzung von Represocial & Affilate und zusätzliche <b>Promotion</b> des Gewinnspiels durch Represocial. <br /><br />  Wir würden sie <b>für Nutzung von Represocial bezahlen</b> und auch für jeden neuen aktiven Nutzer zahlen, der Represocial für seine Kampagnen nutzt, nachdem er durch sie Represocial entdeckt hat. <br /> <br /> Wir würden ihr Gewinnspiel auch <b>auf allen unseren Social Medias promoten</b>, mithilfe von Posts, Stories, Reels. Da wir noch keine große Reichweite haben, werden wir diese zunächst mit gesponsorten und viralen Posts kompensieren. <br /> <br /> Eventuell können wir auch <b>einen Preis für Ihr nächstes Gewinnspiel</b> sponsern, aber das müssen wir direkt mit Ihnen besprechen.

</p>
  );

    // raffles

    if (contentid == "e1r2")
    return (
<p>
We would like to invite you to <b>try out our platform Represocial</b> and directly collaborate with us. You can try out and <b>enjoy all benefits</b> of our platform and also get additional benefits such as money and promotion from us.
<br></br>

<br></br>
<span class="sub-heading">Better Results</span>
With Represocial you can achieve way <b>better results</b> concerning social media growth, through including all your social medias into your raffles. With Represocial you can <b>promote all your social medias</b> in your raflles, and through one simple link, you can share and promote the raffle on all your platforms. By doing that you <b>grow and exchange</b> your audiences across different platforms, and automatically promote the campaign on each platform, even more. It's similar to Google Forms, but <b>natively designed for (sneaker) raffles</b>, so everything is focused on your social media growth and a great experience for your participants.
<br></br>

<br></br>

<div className="offer-icon"><i class="fas fa-hand-holding-usd"></i></div>

<br></br>
<span class="sub-heading">Money and Earnings</span>

We would <b>pay you money</b> to try out Represocial on a CPM basis. We would also <b>pay you the same money</b> (also on a CPM basis), for anybody who signs up and uses Represocial after seeing your campaign or for anybody who you can <b>directly refer</b> to us. And also for all people who sign up or are referred through those people. 

<br></br>

<br></br>

<div className="offer-icon"><div className="socials"><i class="fab fa-instagram"></i> <i class="fab fa-tiktok"></i> <i class="fab fa-youtube"></i></div></div>

<br></br>
<span class="sub-heading">Promotion</span>


Also additionally we would like to <b>promote your giveaway</b> campaign on all our social media channels like Instagram (Stories, Posts, Reels), TikTok (Posts), Twitter (Fleets, Posts), Reddit (Posts), YouTube (Videos, Shorts) ect.

<br></br>

<br></br>

<div className="offer-icon">🤝</div>

<br></br>
<span class="sub-heading">Overall a good tool</span>


In all other aspects, Represocial is also designed in a way, that is the <b>best for (sneaker) raffles</b>, for both organizers and participants. You can collect data from participants, <b>complelty secure and fraud-protected</b>. Als you can easily <b>determine and publicly announce winners</b>. All in under 30 seconds.
</p>
  );

  // classic giveaways 

  if (contentid == "e1g2")
    return (
<p>

We would like to invite you to <b>host a giveaway on our platform Represocial</b> and directly collaborate with us. You can try out and <b>enjoy all benefits of our platform</b> and also get additional benefits such as <b>money and promotion from us</b>.

<br></br>

<br></br>
<span class="sub-heading">Better Results</span>
With Represocial you can achieve <b>way better results</b> for your giveaway campaigns, through including all your social medias into your giveaway. With Represocial you can <b>promote all your social medias</b> in the giveaway campaign, and through one simple link, you can share and promote the giveaway on all your platforms. By doing that you <b>grow and exchange your audiences</b> across different platforms, and automatically promote the campaign on each platform, even more.

<br></br>

<br></br>

<div className="offer-icon"><i class="fas fa-hand-holding-usd"></i></div>

<br></br>
<span class="sub-heading">Money and Earnings</span>

We would <b>pay you money</b> to try out Represocial on a CPM basis. We would also <b>pay you the same money</b> (also on a CPM basis), for anybody who signs up and uses Represocial after seeing your campaign or for anybody who you can <b>directly refer</b> to us. And also for all people who sign up or are referred through those people. 

<br></br>

<br></br>

<div className="offer-icon"><div className="socials"><i class="fab fa-instagram"></i> <i class="fab fa-tiktok"></i> <i class="fab fa-youtube"></i></div></div>

<br></br>
<span class="sub-heading">Promotion</span>


Also additionally we would like to <b>promote your giveaway</b> campaign on all our social media channels like Instagram (Stories, Posts, Reels), TikTok (Posts), Twitter (Fleets, Posts), Reddit (Posts), YouTube (Videos, Shorts) ect.

<br></br>

<br></br>

<div className="offer-icon">🤝</div>

<br></br>
<span class="sub-heading">Collaborative Giveaway</span>


There is also the <b>option of a collaborative giveaway</b> on Represocial. You and other channels with the <b>same reach and topics</b> as you (if you like, you can also invite/host it with friends or people you know), would host a giveaway on Represocial and promote each other. Represocial will completely <b>upfront sponsor</b> the prize for this giveaway and we would discuss and choose the prize together.

</p>
    );

    if (contentid == "r1r2")
    return (
      <p>
Если вы читаете это, получив от нас письмо или сообщение в директ, <b>мы заинтересованы в непосредственном сотрудничестве с вами</b>. Помимо всех преимуществ, которые дает Represocial, мы хотим предложить вам сотрудничество с возможностью <b>заработать</b> на использовании Represocial и аффилиации, а также <b>продвижении</b> ваших раздач с помощью Represocial. <br /><br /> Мы <b>заплатим вам за использование Represocial</b>, а также предложим вам деньги за каждого, кто зарегистрируется и будет использовать Represocial после того, как увидит вашу раздачу. <br /> <br /> Мы будем <b>продвигать ваш розыгрыш с помощью постов, историй и роликов</b> во всех наших социальных сетях, хотя, поскольку мы только начинаем, у нас нет большого охвата, поэтому мы постараемся компенсировать это первыми спонсорскими и вирусными постами. <br /> <br /> Возможно, мы также сможем <b>спонсировать приз для вашего следующего розыгрыша</b>, но мы должны обсудить это с вами лично.

</p>
    );

    if (contentid == "f1r2")
    return (
      <p>
Si vous lisez ce message après avoir recu un email ou un message direct de notre part, <b>nous sommes intéressés à collaborer directement avec vous</b>. En plus de tous les avantages que Represocial a lui-même, nous voulons vous offrir une collaboration avec des opportunités de <b>gagner de l'argent</b> en utilisant Represocial & l'affilation et aussi la <b>promotion</b> de votre concours par Represocial. <br /><br />Nous vous <b>payerons pour utiliser Represocial</b> et nous vous offrirons également de l'argent pour chaque personne qui s'inscrit et utilise Represocial après avoir vu votre concours. <br /> <br /> Nous <b>ferions la promotion de votre giveaway avec des posts, des histoires et des Reels</b> sur tous nos médias sociaux, bien que comme nous commençons tout juste, nous n'avons pas une grande portée à offrir, donc nous allons essayer de compenser avec des premiers posts sponsorisés et viraux. <br /> <br />  Nous pourrions aussi être en mesure de <b>sponsoriser un prix pour votre prochain concours</b>, mais nous devons en discuter directement avec vous.
   </p>
   );

    if (contentid == "s1r2")
    return (
      <p>
Si estás leyendo esto después de recibir un correo electrónico o un mensaje directo de nosotros, <b>estamos interesados en trabajar directamente contigo</b>. Además de todos los beneficios que tiene Represocial en sí mismo, queremos ofrecerle una colaboración con oportunidades de <b>ganar dinero</b> a través del uso de Represocial & affilation y también <b>promoción</b> de su sorteo por Represocial. <br /><br /> Te <b>pagaríamos por usar Represocial</b> y también te ofreceríamos dinero por todos los que se registren y usen Represocial después de ver tu sorteo. <br /> <br /> <b>Promocionaríamos tu sorteo con posts, historias y Reels</b> en todas nuestras redes sociales, aunque como estamos empezando, no tenemos un gran alcance que ofrecer, así que intentaremos compensar con primeros posts patrocinados y virales. <br /> <br />  También podemos financiar un premio para su próximo sorteo, pero tenemos que hablarlo directamente con usted.
</p>
    );

    if (contentid == "g1r2")
    return (
      <p>
  Falls sie diese Seite anschauen, nachdem sie eine Email oder Direktnachricht von uns erhalten haben, <b>sind wir sehr interessiert mit ihnen zusammenzuarbeiten</b>. Neben allen Vorteilen von Represocial, möchten wir ihnen noch eine <b>Einnahme Möglichkeit bieten</b>, durch die Nutzung von Represocial & Affilate und zusätzliche <b>Promotion</b> des Raffles durch Represocial. <br /><br />  Wir würden sie <b>für Nutzung von Represocial bezahlen</b> und auch für jeden neuen aktiven Nutzer zahlen, der Represocial für seine Kampagnen nutzt, nachdem er durch sie Represocial entdeckt hat. <br /> <br /> Wir würden ihr Raffle auch <b>auf allen unseren Social Medias promoten</b>, mithilfe von Posts, Stories, Reels. Da wir noch keine große Reichweite haben, werden wir diese zunächst mit gesponsorten und viralen Posts kompensieren. <br /> <br />
  </p>
    );
}

function GetTitle4() {
  /*
  let { contentid } = useParams();

  if (contentid.includes("e1"))
    return (
      <p>
        An introduction to Represocial
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
        Примеры использования Reprsocial
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
Exemples d'utilisation de Reprsocial
      </p>
    );

    if (contentid.includes("g1"))
    return (
      <p>
        Nutzungsbeispiele von Represocial
      </p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
        Ejemplos de uso de Represocial
      </p>
    );

*/
}

function GetText5() {
  /*
  let { contentid } = useParams();

  if (contentid == "e1")
  return (
    <p>
Here you can watch a quick video that explains the whole quick and easy process when running your raffle/giveaway on Represocial & the exact benefits of each part of it.</p>
  );

  // classic giveaways

  if (contentid == "e1g2")
    return (
      <p>
Here you can watch a quick video that explains the whole quick and easy process when running your giveaway on Represocial & the exact benefits of each part of it.</p>
    );

    if (contentid == "f1g2")
    return (
      <p>
Ici, vous pouvez regarder une vidéo rapide qui explique l'ensemble du processus rapide et facile pour organiser votre concours sur Represocial et les avantages exacts de chaque étape.</p>
    );

    if (contentid == "r1g2")
    return (
      <p>
Здесь вы можете посмотреть видео, в котором объясняется весь быстрый и простой процесс проведения розыгрыша на Represocial и точные преимущества каждого шага.
</p>
    );

    if (contentid == "s1g2")
    return (
      <p>
Aquí puedes ver un video rápido que explica todo el proceso rápido y fácil al ejecutar su sorteo en Represocial y los beneficios exactos de cada parte del mismo.</p>
    );

    if (contentid == "g1g2")
    return (
      <p>
Hier ist ein kurzes Video das die ganze Durchführung eines Gewinnspiels auf Represocial erklärt. Leider auf Englisch, bald kommt eine Deutsche Version.</p>
    );

      // collab giveaways

  if (contentid == "e1cg2")
  return (
    <p>
Here you can watch a quick video that explains the whole quick and easy process when running your giveaway on Represocial & the exact benefits of each part of it.</p>
  );

  if (contentid == "f1cg2")
  return (
    <p>
Ici, vous pouvez regarder une vidéo rapide qui explique l'ensemble du processus rapide et facile pour organiser votre concours sur Represocial et les avantages exacts de chaque étape.</p>
  );

  if (contentid == "r1cg2")
  return (
    <p>
Здесь вы можете посмотреть видео, в котором объясняется весь быстрый и простой процесс проведения розыгрыша на Represocial и точные преимущества каждого шага.
</p>
  );

  if (contentid == "s1cg2")
  return (
    <p>
Aquí puedes ver un video rápido que explica todo el proceso rápido y fácil al ejecutar su sorteo en Represocial y los beneficios exactos de cada parte del mismo.</p>
  );

  if (contentid == "g1cg2")
  return (
    <p>
Hier ist ein kurzes Video das die ganze Durchführung eines Gewinnspiels auf Represocial erklärt. Leider auf Englisch, bald kommt eine Deutsche Version.</p>
  );

    // raffles 

    if (contentid == "e1r2")
    return (
      <p>
Here you can watch a quick video that explains the whole quick and easy process when running your raffle on Represocial & the exact benefits of each part of it.</p>
    );

    if (contentid == "f1r2")
    return (
      <p>
Ici, vous pouvez regarder une vidéo rapide qui explique l'ensemble du processus rapide et facile pour organiser votre raffle sur Represocial et les avantages exacts de chaque étape.</p>
    );

    if (contentid == "r1r2")
    return (
      <p>
Здесь вы можете посмотреть видео, в котором объясняется весь быстрый и простой процесс проведения рафла на Represocial и точные преимущества каждого шага.
</p>
    );

    if (contentid == "s1r2")
    return (
      <p>
Aquí puedes ver un video rápido que explica todo el proceso rápido y fácil al ejecutar su rifa en Represocial y los beneficios exactos de cada parte del mismo.</p>
    );

    if (contentid == "g1r2")
    return (
      <p>
Hier ist ein kurzes Video das die ganze Durchführung eines Raffles auf Represocial erklärt. Leider auf Englisch, bald kommt eine Deutsche Version.</p>
    );
*/
}

function GetText6() {
  /*
  let { contentid } = useParams();

  if (contentid.includes("e1"))
    return (
      <p>
Also here you can check out an example campaign that we have set up to show you, how your campaign might look like.</p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
Vous pouvez ici également voir un exemple de campagne que nous avons mis en place pour vous montrer à quoi pourrait ressembler votre campagne.
</p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
Также здесь вы можете посмотреть пример кампании, которую мы настроили, чтобы показать вам, как может выглядеть ваша кампания.
</p>
    );

        if (contentid.includes("g1"))
    return (
      <p>
    Hier ist ein Beispiel, wie so eine Kampagne auf Represocial aussehen könnte.</p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
    Este es un ejemplo de cómo podría ser una campaña de este tipo en Represocial.</p>
    );

*/
}

function GetText7() {
  /*
  let { contentid } = useParams();

  if (contentid.includes("e1"))
    return (
      <p>
As you see, it is really easy to create & manage, run and determine winners on Represocial.</p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
Как видите, создавать и управлять, проводить и определять победителей на Represocial очень просто.
</p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
Comme vous le voyez, il est très facile de créer et de gérer, d'exécuter et de déterminer les gagnants sur Represocial.
</p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
Como ves, es realmente fácil crear y organizar, ejecutar y determinar los ganadores en Represocial.</p> 
    );


    if (contentid.includes("g1"))
    return (
      <p>
Wir hoffen, es ist bemerkbar geworden, wie einfach es ist eine Kampagne auf Represocial durchzuführen :)</p>
    );
    */
}

function GetTitle5() {
  let { contentid } = useParams();

  if (contentid.includes("e1"))
    return (
      <p>
      Let's get down to business?
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
Давайте сотрудничать?
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
      Collaborons ensemble
            </p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
      Vayamos al grano!
      </p>
    );

    if (contentid.includes("g1"))
    return (
      <p>
      Kommen wir zum Geschäftlichen
      </p>
    );
}

function GetText8() {
  let { contentid } = useParams();

  if (contentid.includes("e1"))
    return (
      <p>
If you got interested in working with us, please <b>reply to our direct message or email</b> (please tell us where to contact you, so we can chat). <br></br><br></br>There are also plenty of other ways of contacting us, so just <b>look at our Contact page</b> that is linked at the bottom of this page. <br></br><br></br>If you want to try out Represocial yourself or already <b>create an account</b> and <b>reserve your username</b> just click at the link below. We are waiting for you (and your reply)!</p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
Si vous êtes intéressé par une collaboration avec nous, veuillez <b>répondre à notre message direct ou à notre e-mail</b> (veuillez nous indiquer où vous contacter, afin que nous puissions discuter). Il y a aussi beaucoup d'autres façons de nous contacter, alors regardez simplement notre page de contact qui est liée au bas de cette page. Si vous voulez essayer Represocial vous-même ou déjà créer un compte et enregistrer votre nom d'utilisateur, cliquez sur le lien ci-dessous :) Nous vous attendons (et votre réponse).
</p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
Если вы заинтересованы в сотрудничестве с нами, пожалуйста, <b>ответьте на наше сообщение или письмо</b> (пожалуйста, укажите, где с вами связаться, чтобы мы могли пообщаться). Существует также множество других способов связаться с нами, так что просто загляните на нашу страницу Контакты, ссылка на которую находится внизу этой страницы. Если вы хотите попробовать Represocial самостоятельно или уже создали аккаунт и сохранили свое имя пользователя, просто нажмите на ссылку ниже :) Мы ждем вас (и вашего ответа).
</p>
    );

  if (contentid.includes("s1"))
  return (
    <p>
Si estás interesado en trabajar con nosotros, por favor, <b>responde a nuestro mensaje directo o correo electrónico</b> (por favor, dinos dónde te podemos contactar). También hay muchas otras formas de contactar con nosotros, así que mira nuestra página de Contacto que está enlazada al final de esta página. Si quieres probar Represocial por ti mismo o ya has creado una cuenta y guardado tu nombre de usuario sólo tienes que hacer clic en el siguiente enlace :) Te esperamos (y tu respuesta).</p>
  );

    if (contentid.includes("g1"))
    return (
      <p>
Falls sie Interesse bekommen haben mit uns Zusammnzuarbeiten, <b>antworten sie bitte auf diese Direktnachricht bzw. Email</b> (falls Email, bitte die beste Kontaktmöglichkeit zum chatten mitteilen). Es gibt außerdem eine große Möglichkeit uns selber zu kontaktieren, dafür einfach die verlinkte Kontaktseite am Ende der Seite anschauen. Falls sie Represocial direkt selber ausprobieren wollen bzw. sich ein Konto & Namen sichern wollen, einfach auf den unteren Link klicken und loslegen. Wir hoffen auf eine Antworten von ihnen :)</p>
    );

}

function LangContent() {
  let { contentid } = useParams();

  //

  if (contentid.includes("g1")) return <p>Kontakt</p>;

  if (contentid.includes("e1")) return <p>Contact</p>;

  if (contentid.includes("s1")) return <p>Contacto</p>;

  if (contentid.includes("r1")) return <p>Контакт</p>;


  if (contentid.includes("f1")) return <p>Contact</p>;

}

function LangContent2() {
  let { contentid } = useParams();

  //

  if (contentid.includes("g1")) return <p>Datenschutz</p>;

  if (contentid.includes("e1")) return <p>Privacy</p>;

  if (contentid.includes("s1")) return <p>Privacidad</p>;

  if (contentid.includes("r1")) return <p>Защита данных</p>;

  if (contentid.includes("f1")) return <p>Confidentialité</p>;
}

function LinkTitle() {
  let { contentid } = useParams();

  if (contentid.includes("g1"))
    return (
      <p>
        Beispiel ansehen
      </p>
    );

  if (contentid.includes("e1"))
    return (
      <p>
        View example campaign
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
        Voir un exemple
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
        Посмотреть пример
      </p>
    );

    if (contentid.includes("s1"))
    return (
      <p>
        Vea un ejemplo de campaña
      </p>
    );
}

function LinkContent2() {
  let { contentid } = useParams();
  const { collabid } = useParams();

    return "https://represocial.com/sign-in?c=" + collabid;
}

function LinkTitle2() {
  let { contentid } = useParams();

  if (contentid.includes("g1"))
    return (
      <p>
        Konto erstellen
      </p>
    );

  if (contentid.includes("e1"))
    return (
      <p>
        Create an account
      </p>
    );

    if (contentid.includes("r1"))
    return (
      <p>
        Создать аккаунт
      </p>
    );

    if (contentid.includes("f1"))
    return (
      <p>
        Créer un compte
      </p>
    );

      if (contentid.includes("s1"))
    return (
      <p>
        Crear una cuenta
      </p>
    );
}

export {
  LangContent,
  LangContent2,
  GetHeading,
  GetText,
  GetTitle,
  GetText2,
  GetTitle2,
  GetText3,
  GetTitle3,
  GetText4,
  GetTitle4,
  GetText5,
  GetText6,
  GetText7,
  GetTitle5,
  GetText8,
  LinkContent,
  YtLinkContent,
  LinkTitle,
  LinkContent2,
  LinkTitle2
};
