import "./App.css";
import App from "./App.js";
import Content from "./Content.js";
import { useParams } from "react-router-dom";
import {
  LangContent,
  LangContent2,
  GetHeading, GetText, GetTitle, GetText2, GetTitle2, GetText3, GetTitle3, GetText4, GetTitle4, GetText5, GetText6, GetText7, GetTitle5, GetText8, LinkContent, LinkTitle, YtLinkContent, LinkContent2, LinkTitle2,
} from "./getContent.js";

function ContentBone() {
  let { contentid } = useParams();
  const langcontent = LangContent();
  const langcontent2 = LangContent2();
  const getheading = GetHeading();
  const gettext = GetText();
  const gettitle = GetTitle();
  const gettext2 = GetText2();
  const gettitle2 = GetTitle2();
  const gettext3 = GetText3();
  const gettitle3 = GetTitle3();
  const gettext4 = GetText4();
  const gettitle4 = GetTitle4();
  const gettext5 = GetText5();
  const gettext6 = GetText6();
  const gettext7 = GetText7();
  const gettitle5 = GetTitle5();
  const gettext8 = GetText8();
  const linkcontent = LinkContent();
  const linktitle = LinkTitle();
  const ytlinkcontent = YtLinkContent();
  const linkcontent2 = LinkContent2();
  const linktitle2 = LinkTitle2();


  return (
    <div className="react-content">
      <div className="react-component">
        <div className="react-title">
          <h1>{getheading}</h1>
        </div>
        <div className="react-logo">
          <img
            src="https://represocial.fra1.digitaloceanspaces.com/ezgif.com-gif-maker.gif"
            width={200}
            height={200}
          />
        </div>
        <div className="react-text">
          <p>{gettext}</p>
        </div>
      </div>
      <div className="react-component">
        <div className="react-title">
          <b></b>
          <h2>{gettitle}</h2>
          <b></b>
        </div>
        <div className="react-text">
          <p>
          {gettext2}
          </p>
        </div>
      </div>
      <div className="react-component">
        <div className="react-title">
          <b></b>
          <h2>{gettitle3}</h2>
          <b></b>
        </div>
        <div className="react-text">
          <p>
          {gettext4}
          </p>
        </div>
      </div>
          </div>
  );
}

function ContentBone2() {
  let { contentid } = useParams();
  const langcontent = LangContent();
  const langcontent2 = LangContent2();
  const getheading = GetHeading();
  const gettext = GetText();
  const gettitle = GetTitle();
  const gettext2 = GetText2();
  const gettitle2 = GetTitle2();
  const gettext3 = GetText3();
  const gettitle3 = GetTitle3();
  const gettext4 = GetText4();
  const gettitle4 = GetTitle4();
  const gettext5 = GetText5();
  const gettext6 = GetText6();
  const gettext7 = GetText7();
  const gettitle5 = GetTitle5();
  const gettext8 = GetText8();
  const linkcontent = LinkContent();
  const linktitle = LinkTitle();
  const ytlinkcontent = YtLinkContent();
  const linkcontent2 = LinkContent2();
  const linktitle2 = LinkTitle2();


  if (contentid == "blabla" ) return (
    <div className="react-content">
      <div className="react-component">
        <div className="react-title">
          <b></b>
          <h2>{gettitle4}</h2>
          <b></b>
        </div>
        <div className="react-text">
          <p>
          {gettext5}
          </p>
        </div>
        <div className="responsive-youtube">
          <iframe
            width={560}
            height={315}
            src={ytlinkcontent}
            className="responsive-youtube"
            title="YouTube video player"
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
        <div className="react-text">
          <p>
          {gettext6}
          </p>
        </div>
        <div className="react-btn">
          <a href={linkcontent}>
            <button className="btn">{linktitle}</button>
          </a>
        </div>
        <div className="react-text">
          <p>
          {gettext7}
          </p>
        </div>


      </div>
    </div>
  );

  if (contentid != "blabla") return (
    <div></div>
    );
}

function ContentBone3() {
  let { contentid } = useParams();
  const langcontent = LangContent();
  const langcontent2 = LangContent2();
  const getheading = GetHeading();
  const gettext = GetText();
  const gettitle = GetTitle();
  const gettext2 = GetText2();
  const gettitle2 = GetTitle2();
  const gettext3 = GetText3();
  const gettitle3 = GetTitle3();
  const gettext4 = GetText4();
  const gettitle4 = GetTitle4();
  const gettext5 = GetText5();
  const gettext6 = GetText6();
  const gettext7 = GetText7();
  const gettitle5 = GetTitle5();
  const gettext8 = GetText8();
  const linkcontent = LinkContent();
  const linktitle = LinkTitle();
  const ytlinkcontent = YtLinkContent();
  const linkcontent2 = LinkContent2();
  const linktitle2 = LinkTitle2();


  return (
    <div className="react-content">
      <div className="react-component">
        


      <div className="react-component">
        <div className="react-title">
          <b></b>
          <h2>{gettitle2}</h2>
          <b></b>
        </div>
        <div className="react-text">
          <p>
          {gettext3}
          </p>
        </div>
      </div>



     
        <div className="react-component">
          <div className="react-title">
            <b></b>
            <h2>{gettitle5}</h2>
            <b></b>
          </div>
          <div className="react-text">
            <p>
            {gettext8}
            </p>
          </div>
          <div>
          <button class="btn"><a className="react-signup" href={linkcontent2}>{linktitle2}
          </a></button>
        </div>
        </div>
        <div className="footer">
          <div>
            <a href="https://represocial.com/contact">{langcontent}</a>
          </div>
          <div>
            <a href="https://represocial.com/privacy">{langcontent2}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export {
  ContentBone,
  ContentBone2,
  ContentBone3,
}